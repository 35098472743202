import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../utils/RouteProtection";

import Lista from "../../pages/lista";
import Boleto from "../../pages/boleto";
import Pagos from "../../pages/pagos";
import Aviso from "../../pages/aviso"; 
import Verifica from "../../pages/verifica"; 
import Landing from "../../pages/landing";
import Home from "../../pages/home";
import Login from "../../pages/login";
import Admin from "../../pages/admin";
import SorteoAdmin from "../../pages/sorteoAdmin";
import Accounts from "../../pages/accounts";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Landing />} />
      <Route path="/login" exact element={<Login/>} />
      <Route path="/admin" exact element={<ProtectedRoute><Admin /></ProtectedRoute>} />
      <Route path="/admin/:slug" element={<ProtectedRoute><SorteoAdmin /></ProtectedRoute>} />
      <Route path="/accounts" element={<ProtectedRoute><Accounts /></ProtectedRoute>} />
      <Route path="/:slug" element={<Home />} />
      <Route path="/:slug/lista" element={<Lista/>} />
      <Route path="/:slug/boleto" element={<Boleto />} />
      <Route path="/:slug/verifica" element={<Verifica />} />
      <Route path="/:slug/pagos" exact element={<Pagos />} />
      <Route path="/:slug/aviso" exact element={<Aviso />} />    
    </Routes>
  );
};

export default Navigation;
