import * as React from "react";

import useAxios from "../utils/useAxios";

import AdminBar from "../compountes/adminbar/adminbar";
import AccountsData from "../compountes/accounts/accounts";

const Accounts = () => {
    const { axios, initialized } = useAxios();

    const [accounts, setAccounts] = React.useState([]);

    React.useEffect(() => {
        const getAccounts = async () => {
            try {
                const response = await axios.get("/accounts");
                setAccounts(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        if (initialized) getAccounts();
    }, [initialized]);


    const createAccount = async (account) => {
        try {
            const response = await axios.post("/accounts/", account);
            setAccounts([...accounts, response.data]);
        } catch (error) {
            alert("Error al crear cuenta");
            console.log(error);
        }
    }

    const updateAccount = async (account) => {
        try {
            await axios.put(`/accounts/${account.id}/`, account);
            setAccounts(accounts.map((acc) => (acc.id === account.id ? account : acc)));
        } catch (error) {
            alert("Error al actualizar cuenta. Los campos nunca deben estar vacios");
            console.log(error);
        }
    }

    const deleteAccount = async (id) => {
        try {
            await axios.delete(`/accounts/${id}/`);
            setAccounts(accounts.filter((account) => account.id !== id));
        } catch (error) {
            alert("Error al eliminar cuenta");
            console.log(error);
        }
    }

    return (
        <div style={{ height: '100vh'}}>
            <AdminBar />
            <AccountsData 
                accounts={accounts} 
                setAccounts={setAccounts}
                createAccount={createAccount}
                updateAccount={updateAccount} 
                deleteAccount={deleteAccount} 
            />
        </div>
    )
}

export default Accounts;