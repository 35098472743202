import React from 'react'

import axios from 'axios'

import { useParams } from 'react-router-dom';

import Token from '../compountes/token/token';

import Topbar from "../compountes/navbar/navbar";
import Footer from "../compountes/footer/footer";

const baseUrl = process.env.REACT_APP_BASE_URL;

const  Verifica = () => {
  
  const { slug } = useParams();

  const [apartados, setApartados] = React.useState([]);
  const [empty, setEmpty] = React.useState(false);

  const getApartados = async (query) => {
    try {
      setEmpty(false);
      const res = await axios.get(`${baseUrl}apartados/${slug}`,
        {
          params: {
            boleto: query,
            phone: query
          }
        });
      setApartados(res.data);
      if (res.data.length === 0) {
        setEmpty(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Topbar slug={slug}/>
      <Token
        slug={slug}
        verify={getApartados}
        apartados={apartados}
        empty={empty}
      />
      <Footer/>
    </div>
  )
}

export default Verifica;
