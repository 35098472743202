import React from "react";

import "./boletogenerator.css";
import imgarrow from "../../assets/images/arrow.png";

import CircularProgress from "@mui/material/CircularProgress";
import BoletoTemplate from "../boletotemplate/boletotemplate";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.updateLocale('en', {
weekdays: [
    "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"
],
weekdaysShort: [
    "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
],
weekdaysMin: [
    "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"
],
months: [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
],
monthsShort: [
    "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
]
})

import { completeNumberFormat, ticketNumberToParticipations } from "../../utils/tickets";

const BoletoGenerator = (props) => {
    const { ticketNumber, sorteoInfo, apartados, getApartados, isEmpty, setIsEmpty } = props;

    const [number, setNumber] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        handleGenerate(ticketNumber);
        setNumber(ticketNumber);
    }, [ticketNumber])

    const handleGenerate = (ticketNumber) => {
        setLoading(true);
        getApartados(ticketNumber)
        setLoading(false);
    }

    return (
        <div className="boletogen-section" id="inicio">
            <div className="boletogen-details-sec">
                <div className="heading-boletogen-details-sec">
                    <p>
                    <strong>GENERA TU BOLETO</strong>
                    </p>
                </div>
                <div className="subheading-boletogen-details-sec">
                    <strong> Para el {sorteoInfo?.title} </strong>
                </div>
            </div>
            <div className="boletogen-details-sec">
                <div className="subheading-boletogen-details-sec">
                    <div className="inner-details">
                        <img src={imgarrow} alt="" />
                    </div>
                    <p> Introduce tu número de boleto y haz click en "Generar" </p>
                </div>
            </div>
            {apartados?.paid ?
                <BoletoTemplate title={sorteoInfo?.title}
                                ticketNumber={completeNumberFormat(parseInt(number), sorteoInfo?.ticketDisplay)}
                                ticketParticipations={ticketNumberToParticipations(parseInt(number), sorteoInfo?.ticketDisplay, sorteoInfo?.ticketPack)} 
                                image={sorteoInfo?.image}
                                name={apartados?.name}
                                lastName={apartados?.lastname}
                                state={apartados?.location.split(",")[1]}
                                city={apartados?.location.split(",")[0]}
                                paidDate={dayjs(apartados?.paid_at).format("DD/MMM/YYYY[,] h:mm a")} />
                :
                null
            }                        
            <div className="lucky-btn text-center">
                <input className="list__search" type="text" placeholder="Número de boleto" value={number} onChange={(e) => {setIsEmpty(false); setNumber(e.target.value)}} />
            </div>
            {loading ?
                <CircularProgress />
                : 
                <button className="button-lo-quiero" onClick={() => {
                    if (!number) {
                        alert("Introduce un número de boleto");
                        return;
                    }
                    handleGenerate(number);
                    }}>
                    Generar
                </button>
            }   
            {isEmpty ?
                <div style={{color: "red", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px"}}>
                    <strong> Boleto {number} no vendido </strong>
                </div>
                :
                null
            }
        </div>
    );
}

export default BoletoGenerator;