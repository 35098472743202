import * as React from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import useAxios from '../utils/useAxios'

import {Typography, Stack} from '@mui/material'
import TableChartIcon from '@mui/icons-material/TableChart';
import SettingsIcon from '@mui/icons-material/Settings';

import AdminBar from "../compountes/adminbar/adminbar";

import SorteoData from '../compountes/sorteodata/sorteodata';
import ApartadosData from '../compountes/apartadosdata/apartadosdata';
import SimpleBackdrop from '../utils/backdrop';

import dayjs from 'dayjs';

const SorteoAdmin = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const { axios, initialized } = useAxios();

    const [sorteo, setSorteo] = React.useState({ apartados: []});
    const [edit, setEdit] = React.useState(false);

    const [actionLoading, setActionLoading] = React.useState(false);
  
    const [uploadImage, setUploadImage] = React.useState(false);
    const [previewURL, setPreviewURL] = React.useState("");

    const [currentTab, setCurrentTab] = React.useState(0);

    React.useEffect(() => {
        const getSorteo = async () => {
            try {
                const response = await axios.get(`/sorteos/${slug}`);
                const sorteo = response.data;
                sorteo.end = dayjs(sorteo.end);
                setSorteo(response.data);
                setPreviewURL(response.data.image)
            } catch (error) {
                console.log(error);
            }
        };
        if (initialized) getSorteo();
    }, [initialized]);

    const updateSorteo = async () => {
        try {
            const form = new FormData();
            form.append('title', sorteo.title);
            form.append('price', sorteo.price);
            form.append('end', sorteo.end.format('YYYY-MM-DD'));
            form.append('slug', sorteo.slug);
            form.append('active', sorteo.active);
            if (sorteo.image && uploadImage) form.append('image', sorteo.image);
            const response = await axios.put(`/sorteos/${slug}/`, form);
            const obj = response.data;
            if (obj.slug !== slug) navigate(`/admin/${obj.slug}`);
        } catch (error) {
            console.log(error);
            alert(error);
        }
        setActionLoading(false);
    };

    const setEditWithPut = async (edit) => {
        if (!edit) await updateSorteo();
        setEdit(edit);
    }

    const markApartadosPaid = async (apartados, paymentMethod) => {
        setActionLoading(true);
        try {
            let newApartados = [];
            for (const apartado of apartados) {
                const response = await axios.put(`/apartados/${slug}/${apartado.id}/`, {
                    paid: true,
                    payment_method: paymentMethod,
                });
                newApartados.push(response.data);
            };
            setSorteo({ ...sorteo, apartados: sorteo.apartados.map((apartado) => {
                const newApartado = newApartados.find((a) => a.id === apartado.id);
                return newApartado ? newApartado : apartado;
                })
            });
        } catch (error) {
            console.log(error);
            alert(error);
        }
        setActionLoading(false);
    }

    const markApartadosUnPaid = async (apartados) => {
        setActionLoading(true);
        setTimeout(() => setActionLoading(false), 1000);
        try {
            let newApartados = [];
            for (const apartado of apartados) {
                const response = await axios.put(`/apartados/${slug}/${apartado.id}/`, {
                    paid: false,
                });
                newApartados.push(response.data);
            }
            setSorteo({ ...sorteo, apartados: sorteo.apartados.map((apartado) => {
                const newApartado = newApartados.find((a) => a.id === apartado.id);
                return newApartado ? newApartado : apartado;
                })
            });
        } catch (error) {
            console.log(error);
            alert(error);
        }
        setActionLoading(false);
    };

    const sendWhatsapp = (apartados) => {
        if (apartados.length !== 1) {
            alert('No puedes mandar mensajes a múltiples apartados. Selecciona solo uno.')
            return;
        }
        const apartado = apartados[0]
        if (!apartado.paid) {
            alert('No se generará porque no está pagado.')
            return;
        }
        const numbersUnsorted = apartado.boletos.map((boleto) => boleto.no);
        const numbers = numbersUnsorted.sort((a, b) => a - b);
        const n = numbers.length / sorteo.ticket_pack;
        const firstN = numbers.slice(0, n);
        const links = firstN.map((no) => `https://${window.location.host}/${slug}/boleto?number=${no}`);
        const message = `Listo muchas gracias!! Te deseo la mejor de las suertes!! 🍀🍀👍\n\nEncuentra todos tus boletos PAGADOS aquí: \n${links.join('\n')}`;
        const waLink = `https://wa.me/${apartado.phone}?text=${encodeURIComponent(message)}`;
        window.open(waLink, '_blank');
    }

    const deleteApartado = async (apartados) => {
        setActionLoading(true);
        try {
            for (const apartado of apartados) {
                await axios.delete(`/apartados/${slug}/${apartado.id}/`);
            }
            setSorteo({ ...sorteo, apartados: sorteo.apartados.filter((apartado) => !apartados.includes(apartado)) });
        } catch (error) {
            console.log(error);
        }
        setActionLoading(false);
    }

    const handleTabChange = (newValue) => {
        setCurrentTab(newValue);
    };


    const tabs = [
        {
            label: 'Apartados',
            icon: TableChartIcon
        },
        {
            label: 'Ajustes',
            icon: SettingsIcon
        }
    ]

    const activeTabStyle = {
        borderBottom: '3px solid #3f51b5',
        cursor: 'pointer'
      }
      
    const inactiveTabStyle = {
        borderBottom: '3px solid transparent',
        cursor: 'pointer'
    }

    const tabContent = [
        <ApartadosData 
                apartados={sorteo.apartados} 
                pack={sorteo.ticket_pack} 
                markApartadosPaid={markApartadosPaid}
                markApartadosUnPaid={markApartadosUnPaid}
                sendWhatsapp={sendWhatsapp}
                deleteApartado={deleteApartado}
        />,
        <SorteoData 
            sorteo={sorteo} 
            setSorteo={setSorteo} 
            edit={edit}
            setEdit={setEditWithPut}
            setUploadImage={setUploadImage}
            previewURL={previewURL}
            setPreviewURL={setPreviewURL}
        />
    ]

    return (
        <div style={{ height: '100vh',}}>
            <AdminBar />
            <div style={{display:'flex', justifyContent:'center'}}>
                <Typography variant='h6' style={{padding:'1rem'}}>
                    {sorteo.title}
                </Typography>
            </div>
            <Stack direction="row" sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:'5%' }}>
                {tabs.map((tab, index) => (
                    <Stack key={index} spacing='5%' sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <tab.icon sx={{ width:'1rem', height:'1rem', cursor: 'pointer'}} onClick={() => handleTabChange(index)}/>
                        <Typography variant="body1" textAlign='left' onClick={() => handleTabChange(index)}
                                    sx={currentTab === index ? {...activeTabStyle} : {...inactiveTabStyle}}>
                            {tab.label}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
            <div>
                {tabContent[currentTab]}
            </div>
            <SimpleBackdrop open={actionLoading} />
        </div>
    )
}

export default SorteoAdmin