import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../token/token.css";
import logo from "../../assets/images/logo.png";
import arrow from "../../assets/images/arrow.png";


const Token = (props) => {

  const { apartados,  verify, empty } = props;

  const [query, setQuery] = React.useState('')

  function commaSeparate(numbers) {
    return numbers.join(', ');
  }

  return (
    <div className="Verifica-section">
      <img src={logo} alt="" />
      <div className="Verifica-heading">
        <p className="verificador_p">
          <strong>VERIFICADOR DE BOLETOS</strong>
        </p>
      </div>
      <div className="verificador_div">
        <p className="verificador_para">
          <img src={arrow} alt="" /> Introduce tu BOLETO ó CELULAR y haz click
          en "Verificar"
        </p>
        <div className="form py-5">
          <input
            type="text"
            inputmode="numeric"
            placeholder="Escribe Boleto ó Celular"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button onClick={() => verify(query)}>
            Verificar
          </button>
        </div>
        <div className="ticket-verify__table-container">
        {
          apartados.length !== 0 && (
        <table className="ticket-verify__table">
          <thead>
            <tr>
              <th>Oportunidades</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Estado</th>
              <th>Pagado</th>
            </tr>
          </thead>
          <tbody>
            {
              apartados.map((apartado, index) => (
                <tr>
                  <td>
                    <>{apartado.boletos.map((boleto) => boleto.no).join(', ')}</>
                  </td>
                  <td>{apartado.name}</td>
                  <td>{apartado.lastname}</td>
                  <td>{apartado.location}</td>
                  <td className={apartado.paid ? 'verificador_greenFlag': 'verificador_redFlag'}>
                    {apartado.paid ? 'SI' : 'NO'}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
          )
        }
        {
          empty && (
            <div className="no-results">
              <p style={{ color: 'red'}}>
                No se encontraron resultados
              </p>
            </div>
          )
        }
        </div>
      </div>
    </div>
  );
};

export default Token;
