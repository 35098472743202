import * as React from 'react';

import "./accounts.css"

import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FormControl, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';


const bankOptions = [
    'bbva',
    'citibanamex',
    'banorte',
    'santander',
    'hsbc',
    'scotiabank',
    'banregio',
    'banbajio',
    'inbursa',
    'afirme',
    'bancoppel',
    'bancoazteca',
    'spin',
    'invex',
    'actinver',
    'ixe',
    'monex',
    'multiva',
    'bansi',
]

const AccountsData = ({ accounts, createAccount, updateAccount, deleteAccount }) => {
    
    const [open, setOpen] = React.useState(false);

    const [account, setAccount] = React.useState({
        name: '',
        card: '',
        account: '',
        active: false,
        bank: ''
    });

    return (
        <div className='accounts-container'>
            {accounts.map((account) => (
                <Stack spacing={10} direction='row' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'space-between'}}>
                        <TextField
                            id="outlined-read-only-input"
                            label="Nombre"
                            value={account.name || ''}
                            onChange={(e) => {
                                updateAccount({ ...account, name: e.target.value })
                            }}
                        />
                        <TextField
                            id="outlined-read-only-input"
                            label="Tarjeta"
                            value={account.card || ''}
                            onChange={(e) => {
                                updateAccount({ ...account, card: e.target.value })
                            }}
                        />
                        <TextField
                            id="outlined-read-only-input"
                            label="Clabe"
                            value={account.account || ''}
                            onChange={(e) => {
                                updateAccount({ ...account, account: e.target.value })
                            }}
                        />
                        <FormControl>
                            <FormControlLabel
                                control={<Checkbox checked={account.active} onChange={() => updateAccount(
                                    { ...account, active: !account.active }
                                )} />}
                                label="Activo"
                            />
                        </FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={account.bank || ''}
                            onChange={(e) => {
                                updateAccount({ ...account, bank: e.target.value })
                            }}
                            sx={{ width: '200px' }}
                        >
                            {bankOptions.map((bank) => (
                                <MenuItem value={bank}>{bank}</MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    <Button 
                        onClick={() => {
                            deleteAccount(account.id);
                        }} 
                        variant='contained' color="error"
                    >
                        Eliminar
                    </Button>
                </Stack>
            ))
            }
            <Button 
                onClick={() => {
                    setOpen(true);
                }} 
                variant='contained'
                sx={{ marginTop: '20px' }}
            >
                Agregar
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Crear cuenta</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Agrega los datos de la cuenta
                    </DialogContentText>
                    <TextField
                        id="outlined-read-only-input"
                        label="Nombre"
                        value={account.name || ''}
                        onChange={(e) => {
                            setAccount({ ...account, name: e.target.value })
                        }}
                    />
                    <TextField
                        id="outlined-read-only-input"
                        label="Tarjeta"
                        value={account.card || ''}
                        onChange={(e) => {
                            setAccount({ ...account, card: e.target.value })
                        }}
                    />
                    <TextField
                        id="outlined-read-only-input"
                        label="Clabe"
                        value={account.account || ''}
                        onChange={(e) => {
                            setAccount({ ...account, account: e.target.value })
                        }}
                    />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={account.bank || ''}
                        onChange={(e) => {
                            setAccount({ ...account, bank: e.target.value })
                        }}
                        sx={{ width: '200px' }}
                    >
                        {bankOptions.map((bank) => (
                            <MenuItem value={bank}>{bank}</MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button onClick={() => {
                            setOpen(false);
                            createAccount(account);
                        }}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AccountsData