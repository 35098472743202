import React from 'react'

import axios from 'axios';

import Info from '../compountes/info/info';

import Topbar from "../compountes/navbar/navbar";
import Footer from "../compountes/footer/footer";

import { useParams } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Pagos = () => {
  const { slug } = useParams();
  const [accounts, setAccounts] = React.useState([]);

  React.useEffect(() => {
    const getAccounts = async () => {
      try {
        const res = await axios.get(`${baseUrl}accounts`, {
          params: {
            active: 1
          }
        });
        setAccounts(res.data);
      } catch (error) {
        alert("Error al obtener las cuentas");
      }
    }
    getAccounts();
  }, [])

  return (
    <div>
      <Topbar slug={slug}/>
      <Info accounts={accounts}/>
      <Footer/>
    </div>
  )
}

export default Pagos;
