import * as React from "react";

import "./createsorteo.css"

import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';

const CreateSorteo = ({ createSorteo, loading }) => {

    const [open, setOpen] = React.useState(false);

    const [title, setTitle] = React.useState("");
    const [price, setPrice] = React.useState("");
    const [tickets, setTickets] = React.useState("");
    const [ticket_pack, setTicket_pack] = React.useState("");
    const [slug, setSlug] = React.useState("");
    const [endDate, setEndDate] = React.useState(dayjs());

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div className="create-sorteo-container">
            <Button variant="outlined" onClick={handleClickOpen}>
                Crear Sorteo
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Crea un nuevo sorteo</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Introduce todos los datos solicitados para crear un nuevo sorteo. El proceso puede tardar unos minutos cuando mucho, no cierres la ventana.
                </DialogContentText>
                    {
                        loading ?
                        <CircularProgress />
                        :
                        <div>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="title"
                                label="Título"
                                type="text"
                                fullWidth
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                id="price"
                                label="Precio del boleto"
                                type="number"
                                fullWidth
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                id="tickets"
                                label="Número de boletos totales"
                                type="number"
                                fullWidth
                                value={tickets}
                                onChange={(e) => setTickets(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                id="ticket_pack"
                                label="Número de boletos por paquete"
                                type="number"
                                fullWidth
                                value={ticket_pack}
                                onChange={(e) => setTicket_pack(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                id="slug"
                                label="URL del sorteo"
                                type="text"
                                fullWidth
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Fecha de cierre"
                                    value={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    renderInput={(params) => <TextField {...params} />}
                                    sx={{ margin: '1rem' }}
                                />
                            </LocalizationProvider>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    handleClose();
                    createSorteo({
                        title,
                        price,
                        tickets,
                        ticket_pack,
                        slug,
                        end: endDate.format("YYYY-MM-DD")
                    });
                }}>
                    Confirmar
                </Button>
                </DialogActions>
            </Dialog>
            </div>
    )
}

export default CreateSorteo;