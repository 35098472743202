import React from 'react'
import Avisoinfo from '../compountes/avisoinfo/avisoinfo'

import Topbar from "../compountes/navbar/navbar";
import Footer from "../compountes/footer/footer";

import { useParams } from "react-router-dom";

const Aviso = () => {
  const { slug } = useParams();
  return (
    <div>
      <Topbar slug={slug} />
      <Avisoinfo/>
      <Footer/>
    </div>
  )
}

export default Aviso;
