import React from "react";
import axios from "axios";

import MainheroLanding from "../compountes/mainherolanding/mainherolanding";
import Topbar from "../compountes/navbar/navbar";
import Footer from "../compountes/footer/footer";

const Landing = () => {
  const [activeSorteos, setActiveSorteos] = React.useState([]);

  React.useEffect(() => {
    const getActiveSorteos = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}sorteo/`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const activeSorteos = res.data;
        setActiveSorteos(activeSorteos);
      } catch (err) {
        console.error(err);
      }
    };
    getActiveSorteos();
  }, []);

  return (
    <div>
      <Topbar />
      <MainheroLanding activeSorteos={activeSorteos} />
      <Footer />
    </div>
  );
};

export default Landing;