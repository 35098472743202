import React  from "react";
import "../../compountes/lucky/lucky.css";
import { Container} from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import whimg from "../../assets/images/whitearrow.webp";
import Listamodel from "../listamodel/listamodel";
import { completeNumberFormat, ticketNumberToParticipations } from "../../utils/tickets";

const Lucky = (props) => { 
  const { slug, tickets, setTickets, sorteoInfo, postForm } = props;
  const [ticketsToTake, setTicketsToTake] = React.useState(new Set());
  const [openModal, setOpenModal] = React.useState(false);
  const [ticketToLookup, setTicketToLookup] = React.useState(null);

  const ticketDisplay = sorteoInfo?.ticketDisplay;
  const ticketPack = sorteoInfo?.ticketPack;

  return (
    <div className="lucky-num-section">
      <div className="lucky-heading">
        <Container fluid="lg">
          <div className="inner-heading">
            <img src={whimg} alt="" />
            <p>
              HAZ CLICK ABAJO EN TU <br className="br" /> NÚMERO DE LA SUERTE
            </p>
            <img src={whimg} alt="" />
          </div>
        </Container>
      </div>
      <div className="lucky-heading-sticky">
        <Container fluid="lg">
          {ticketsToTake.size > 0 ?
            <div className="inner-heading">
              <div className="apartar-div">
                <button className="button-apartar" onClick={() => setOpenModal(true)}>
                  APARTAR
                </button>
                <div className="list-buttons">
                  <div className="inner-list-buttons">
                    {Array.from(ticketsToTake).map( (ticketId, index) => (
                      <button className="list-button-num-taken"
                              key={index}
                              onClick={() => {
                                          const newTicketsToTake = new Set(ticketsToTake);
                                          newTicketsToTake.delete(ticketId);
                                          setTicketsToTake(newTicketsToTake);
                                        }
                                      }>
                        {completeNumberFormat(tickets.find(ticket => ticket.id === ticketId).no, ticketDisplay)}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="apartar-info">
                  <span>{ticketsToTake.size} BOLETOS SELECCIONADOS</span>
                  <span>Para remover haz click en el boleto</span>
                </div>
                <div className="apartar-oportunidades">
                  <span>Oportunidades:</span>
                  <div className="overflower">
                    {Array.from(ticketsToTake).map( (ticketId, index) => (
                      <span key={index}>
                        {completeNumberFormat(tickets.find(ticket => ticket.id === ticketId).no, ticketDisplay)}: [{ticketNumberToParticipations(tickets.find(ticket => ticket.id === ticketId).no, ticketDisplay, ticketPack).join(', ')}]
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
        </Container>
      </div>
      <div className="lucky-btn text-center">
        <input className="list__search" type="text" placeholder="BUSCAR"
               value = {ticketToLookup === null || parseInt(ticketToLookup) === 0 ? "" : ticketToLookup}
               onChange={(e) => setTicketToLookup(e.target.value ? completeNumberFormat(parseInt(e.target.value), ticketDisplay) : null)} />
        <Listamodel slug={slug} tickets={tickets} setTickets={setTickets} sorteoInfo={sorteoInfo} ticketsToTake={ticketsToTake} setTicketsToTake={setTicketsToTake} openModal={openModal} setOpenModal={setOpenModal} postForm={postForm}/>
      </div>
      <div className="list-buttons">
        <Container fluid="lg">
          {tickets.length > 0 ? (
            ticketToLookup === null || parseInt(ticketToLookup) > ticketDisplay * ticketPack || parseInt(ticketToLookup) === 0  ? (
              <div className="inner-list-buttons">
                {Array.from({ length: ticketDisplay }, (_, index) => (
                    tickets[index].taken || ticketsToTake?.has(tickets[index].id) ? (
                      <button className="list-button" key={index}>-</button>
                    ) : (
                      <button className={ticketsToTake?.has(tickets[index].id) ? "list-button" : "list-button-num"}
                              key={index}
                              onClick={() => {
                                          const newTicketsToTake = new Set(ticketsToTake);
                                          newTicketsToTake.add(tickets[index].id);
                                          setTicketsToTake(newTicketsToTake);
                                        }
                                      }>
                                {completeNumberFormat(tickets[index].no, ticketDisplay)}
                      </button>
                    )
                  )
                )}
              </div>
              ) : (
              <div className="inner-list-buttons" style={{display: 'flex', justifyContent: 'center'}}>
                <button className={ticketsToTake?.has(tickets.find(ticket => ticket.no === parseInt(ticketToLookup))?.id) ? "list-button" : "list-button-num"}
                        onClick={() => {
                                    const newTicketsToTake = new Set(ticketsToTake);
                                    const ticketToAdd = tickets.find(ticket => ticket.no === parseInt(ticketToLookup) % ticketDisplay);
                                    if (ticketToAdd) {
                                      newTicketsToTake.add(ticketToAdd.id);
                                      setTicketsToTake(newTicketsToTake);
                                    }
                                  }
                                }>
                  {completeNumberFormat(parseInt(ticketToLookup) % ticketDisplay, ticketDisplay)}
                </button>
              </div>
              )
            )
            : (
            <div className="inner-list-buttons" style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress />
            </div>
            )
          } 
        </Container>
      </div>
    </div>
  );
};

export default Lucky;
