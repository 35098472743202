import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./avisoinfo.css";
const Avisoinfo = () => {
  return (
    <div className="avisoinfo-section">
      <Container fluid="lg">
        <Row className="mx-0 justify-content-center">
           <h5 className="text-center aviso-heading">INFORMACIÓN GENERAL</h5>
          <Col lg={8} md={10} sm={12}>
             <div className="aviso-para">
                <p>SORTEOS CHOYS se compromete a cumplir con las bases de los sorteos y a hacer cumplir las reglas de los mismos.</p>
                <p>
                La información e imágenes publicadas en este sitio han sido proporcionadas por SORTEOS CHOYS ; quien es responsable del sitio web www.choysorteos.com , del contenido publicado, de los sorteos y del tratamiento de la información proporcionada o recabada en el mismo.
                </p>
             </div>
             <h5 className="text-center aviso-heading">
             AVISO DE PRIVACIDAD PARA PROSPECTOS
             </h5>
             <div className="aviso-para">
                <p>DATOS PERSONALES RECABADOS POR SORTEOS CHOYS</p>
                <p>
                Para efectos de cumplir con las finalidades previstas en este Aviso de Privacidad, recabaremos de usted los siguientes datos personales:
                </p>
                <p>
                Datos de identificación <br />
                Datos de contacto
                </p>
                <p>Le informamos que los datos personales anteriores los podemos obtener cuando usted nos los proporciona de forma directa o bien de forma indirecta a través de fuentes de acceso público.</p>
                <p>
                USO DE DATOS PERSONALES
                </p>
                <p>
                Finalidades primarias:
                </p>
                <p>
                Los datos personales que usted nos proporcione podrán ser utilizados para las siguientes finalidades:
                </p>
                <p>
                - Para identificarle y registrarle como un posible prospecto de venta.
                <br />
                - Para determinar si usted es apto para adquirir nuestros productos o servicios.
                <br />
                - Para contactarle, a través de diversos medios de comunicación físicos y electrónicos con el propósito de ofrecerle nuestros productos y servicios.
                <br />
                - Para dar seguimiento a sus solicitudes de información sobre nuestros productos y servicios o cualquier otra cuestión que resulte de nuestra competencia.
                <br />
                - Para cumplir con obligaciones previstas en la normatividad aplicable así como cooperar con autoridades competentes en caso de que así se requiera.
                </p>
                <p>
                MECANISMOS PARA EXPRESAR NEGATIVA AL TRATAMIENTO DE SUS DATOS PERSONALES
                </p>
                <p>
                En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades secundarias, desde este momento nos puede comunicar lo anterior al whatsapp: (432) 100 1767
                </p>
                <p>
                La negativa para el uso de sus datos personales para fines adicionales o secundarios, no podrá ser un motivo para negarle los servicios establecidos o dar por terminada la relación establecida con nosotros.
                </p>
                <p>
                CON QUIÉN PODEMOS COMPARTIR SUS DATOS PERSONALES
                </p>
                <p>
                Para cumplir con las finalidades exigidas legalmente o por autoridades competentes,SORTEOS CHOYS  sólo transferirá los datos necesarios en los casos legalmente previstos.
                </p>
                <p>
                Tercero receptor de los datos personales: Autoridades competentes
                <br />
                Finalidad: En los casos legalmente previstos
                <br />
                Consentimiento: No necesario
                </p>
                <p>MEDIOS PARA EJERCER LOS DERECHOS ARCO Y/O REVOCACIÓN DEL CONSENTIMIENTO</p>
                <p>
                Usted por sí o mediante representante legal debidamente acreditado, podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición (ARCO), así como revocar su consentimiento para el tratamiento de sus datos personales. En este sentido, puede informarse sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento en nuestra página de internet: http://www.choysorteos.com/aviso-privacidad/
                </p>
                <p>
                COOKIES
                </p>
                <p>
                SORTEOS CHOYS utiliza varias tecnologías para mejorar la eficiencia de sus sitios web, incluyendo su experiencia cuando navega por dichos sitios. Entre estas tecnologías se incluye el uso de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta información permite identificarle y guardar sus preferencias personales para brindarle una mejor experiencia de navegación. Le recordamos que usted puede deshabilitar o ajustar el uso de cookies siguiendo los procedimientos del navegador de internet que utiliza.
                </p>
                <p>
                AUTORIDAD
                </p>
                <p>
                En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.
                </p>
                <p>
                ACEPTACIÓN DEL AVISO DE PRIVACIDAD
                </p>
                <p>
                Fecha de última actualización: 12 de Mayo de 2022.
                </p>
                <h5 className="text-center aviso-heading">
                AVISO DE PRIVACIDAD PARA PARTICIPANTES
             </h5>
             <div className="aviso-para">
              <p>
              SUS DATOS PERSONALES Y DATOS PERSONALES SENSIBLES
              </p>
              <p>
              Los datos personales que SORTEOS CHOYS  obtiene por parte de sus usuarios, clientes, proveedores y/o cualquier persona relacionada con sus actividades, se encuentran mencionados en la siguiente clasificación:
              </p>
              <p>
              Datos de Identificación.
              <br />
              Datos de Contacto.
              <br />
              Datos de tarjetas de crédito.
              </p>
              <p>
              Le informamos que el tratamiento de dichos datos es necesario para cumplir las obligaciones derivadas de la relación jurídica entre usted y SORTEOS CHOYS , razón por la cual su consentimiento se encuentra exceptuado.
              </p>
              <p>
              USO DE SUS DATOS PERSONALES.
              </p>
              <p>
              Finalidades primarias:
              </p>
              <p>
              -Identificación.
              <br />
              -Contacto para temas relacionados con el pago del boleto que adquirió, la premiación, en su caso, del boleto que adquirió.
              <br />
              -Informar acerca del desarrollo y resultados de los distintos sorteos.
              <br />
              -Realizar publicaciones dentro de nuestras listas de ganadores, en caso de resultar agraciado el boleto que adquirió.
              </p>
              <p>
              Le informamos que usted no puede solicitar que SORTEOS CHOYS  cese el tratamiento de sus datos para las finalidades primarias y necesarias, anteriormente mencionadas, en virtud de que el tratamiento es necesario para cumplir obligaciones derivadas de la relación entre usted y SORTEOS CHOYS.
              </p>
              <p>
              Finalidades secundarias:
              </p>
              <p>
              Una vez que se haya cumplido con el desarrollo del sorteo en que usted participe, SORTEOS CHOYS  conservará su información personal:
              </p>
              <p>
              -Para informarle sobre nuestros avances, eventos, promociones y servicios de Sorteos Sonora así como, para mantener contacto con usted como participante.
              <br />
              -Solicitar información para realizar investigaciones de mercado y promoción de productos.
              <br />
              -Hacer consultas, investigaciones y revisiones en relación a sus quejas o reclamaciones.
              <br />
              -Para fines estadísticos.
              </p>
              <p>
              MECANISMOS PARA EXPRESAR NEGATIVA AL TRATAMIENTO DE SUS DATOS PERSONALES
              </p>
              <p>
              En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades secundarias, desde este momento nos puede comunicar lo anterior al whatsapp: (432) 100 1767
              </p>
              <p>
              La negativa para el uso de sus datos personales para fines adicionales o secundarios, no podrá ser un motivo para negarle los servicios establecidos o dar por terminada la relación establecida con nosotros.
              </p>
              <p>
              CON QUIÉN PODEMOS COMPARTIR SUS DATOS PERSONALES.
              </p>
              <p>
              SORTEOS CHOYS para cumplir las finalidades exigidas legalmente o por las autoridades competentes sólo transferirá los datos necesarios en los casos legalmente previstos.
              </p>
              <p>
              Tercero receptor de los datos personales: Instituciones bancarias, Autoridades competentes
              <br />
              Finalidad: Para realizar las gestiones de pagos necesarias, En los casos legalmente previstos
              <br />
              Consentimiento: No necesario
              </p>
              <p>
              MEDIOS PARA EJERCER LOS DERECHOS ARCO Y/O REVOCACIÓN DEL CONSENTIMIENTO
              </p>
              <p>
              Usted por sí o mediante representante legal debidamente acreditado, podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición (ARCO), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un whatsapp a (432) 100 1767
              </p>
              <p>
              En este sentido, puede informarse sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento en nuestra página de internet: http://www.choysorteos.com/aviso-privacidad/
              </p>
              <p>
              CÓMO PROTEGEMOS Y EVITAMOS LA DIVULGACIÓN DE SUS DATOS PERSONALES.
              </p>
              <p>
              Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a SORTEOS CHOYS  al whatsapp (432) 100 1767. En caso de que su solicitud sea procedente se le registrará en el listado de exclusión propio de SORTEOS CHOYS
              </p>
              <p>
              ACTUALIZACIONES Y/O MODIFICACIONES AL AVISO DE PRIVACIDAD.
              </p>
              <p>
              SORTEOS CHOYS le notificará de cualquier cambio a su aviso de privacidad a través de la página: http://www.choysorteos.com/aviso-privacidad/
              </p>
              <p>
              COOKIES
              </p>
              <p>
              SORTEOS CHOYS utiliza varias tecnologías para mejorar la eficiencia de sus sitios web, incluyendo su experiencia cuando navega por dichos sitios. Entre estas tecnologías se incluye el uso de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta información permite identificarle y guardar sus preferencias personales para brindarle una mejor experiencia de navegación. Le recordamos que usted puede deshabilitar o ajustar el uso de cookies siguiendo los procedimientos del navegador de internet que utiliza.
              </p>
              <p>
              AUTORIDAD
              </p>
              <p>
              En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.
              </p>
              <p>
              ACEPTACIÓN DEL AVISO DE PRIVACIDAD
              </p>
              <p>
              Al utilizar nuestros productos y/o servicios, usted acepta el tratamiento de su información personal de acuerdo al contenido de nuestro aviso de privacidad.
              </p>
              <p>
              Fecha de última actualización: 12 de Mayo de 2022.
              </p>
             </div>
             </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Avisoinfo;
