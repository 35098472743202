import React from "react";
import { Container } from "react-bootstrap";
import "./hero.css";
import imgarrow from "../../assets/images/arrow.png";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.updateLocale('en', {
weekdays: [
    "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"
],
weekdaysShort: [
    "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
],
weekdaysMin: [
    "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"
],
months: [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
],
monthsShort: [
    "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
]
})

const Hero = (props) => {
  const {title, date, image, price, ticketPack, bonos, presorteos} = props;
  return (
    <div className="hero-section">
      <div className="hero-heading">
        <Container fluid="lg">
          <div className="inner-heading">
            <p>
              <strong>{title?.toUpperCase()}</strong>
            </p>
            <p className="para2">
              <strong>{dayjs(date).format("DD [de] MMMM [de] YYYY")}</strong>
            </p>
          </div>
        </Container>
      </div>
      <div className="tickets-section">
        <Container fluid="lg">
          <div className="inner-details">
            <img src={imgarrow} alt="" />
            <p>LISTA DE BOLETOS ABAJO</p>
            <img src={imgarrow} alt="" />
          </div>
          <div className="win-car">
            <img src={image} alt="" loading="lazy" />
          </div>
        </Container>
      </div>
      <div className="tickets-details">
        <div className="one-tickets-details">
          <Container fluid="lg">
            <p>1 BOLETO POR ${price} ({ticketPack} oportunidades)</p>
          </Container>
        </div>
        <div className="intire-tickets-details">
          <div className="para-tickets">
            <p>Con tu boleto liquidado participas por:</p>
            <p>
              <span>{title?.toUpperCase()}</span>- {dayjs(date).format("DD [de] MMMM")}
            </p>
          </div>
          <br />
          <br className="br" />
          { 
          presorteos.length > 0 &&
          <div className="para-tickets">
            <span>PRESORTEOS:</span>
            {
              presorteos.map((presorteo, index) => (
                <div className="para-tickets">
                  <p>
                    <span>{presorteo.prize} -</span>{dayjs(presorteo.end).format("DD [de] MMMM")}
                  </p>
                </div>
              ))
            }
          </div>
          }
          <br className="br" />
          <br />
          { 
          bonos.length > 0 &&
          <div className="para-tickets">
            <span>BONOS:</span>
            {
              bonos.map((bono, index) => (
                <>
                <p>{bono.description}</p>
                <span>{bono.prize}</span>
                </>
              ))
            }
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Hero;
