import axios from "axios";
import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";


const baseURL = process.env.REACT_APP_BASE_URL || "https://sorteoschoys.azurewebsites.net/";

const AuthContext = createContext({});

export default AuthContext;
export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() => {
      if (typeof window !== "undefined") {
        return localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens") || '{}')
        : null;
      }
    }
    );
    const [user, setUser] = useState(() => {
      if (typeof window !== "undefined") {
        const authTokens = localStorage.getItem("authTokens")
        return authTokens
        ? jwt_decode(JSON.parse(localStorage.getItem("authTokens") || '{}').access)
        : null;
      }
    }
    );  
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
  
    const loginUser = async (username, password) => {
      const response = await fetch(`${baseURL}token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          username,
          password
        })
      });
      const data = await response.json();
  
      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        navigate("/admin");
      } else {
        alert("Something went wrong!");
      }
      setLoading(false);
    };
    
    const registerUser = async (username, firstName, lastName,  password, confirmPassword) => {
      const response = await fetch(`${baseURL}register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          email: username,
          name: firstName,
          surname: lastName,
          password,
          confirm_password: confirmPassword
        })
      });
      if (response.status === 201) {
        const data = await response.json();
        setAuthTokens(data.auth_token);
        setUser(jwt_decode(data.auth_token.access));
        localStorage.setItem("authTokens", JSON.stringify(data.auth_token));
        navigate("/admin");
      } else {
        const data = await response.json();
        let errors = "";
        for (const key in data) {
          errors += data[key] + "\n";
        }
        alert(errors);
      }
    };

    const logoutUser = () => {
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      navigate("/login");
    };
  
    const contextData = {
      user,
      setUser,
      authTokens,
      setAuthTokens,
      registerUser,
      loginUser,
      logoutUser
    };
  
    useEffect(() => {
      if (authTokens) {
        authTokens.access && setUser(jwt_decode(authTokens.access));
      }
      setLoading(false);
    }, [authTokens, loading]);
  
    return (
      <AuthContext.Provider value={contextData}>
        {loading ? null : children}
      </AuthContext.Provider>
    );
  };