export function completeNumberFormat(number, ticketDisplay) {
  
  if (number === null || number === undefined || Number.isInteger(number) === false) {
    return null;
  }
  const digits = ticketDisplay.toString().length;
  const numberString = number.toString().slice(-digits);
  return numberString.padStart(digits, '0')
}

export function ticketNumberToParticipations(ticketNumber, ticketDisplay, ticketPack) {
  const participations = [];
  for (let i = 1; i < ticketPack; i++) {
    participations.push(completeNumberFormat(ticketNumber + ticketDisplay * i, ticketDisplay));
  }
  return participations;
}