import React from "react";
import "../info/info.css";
import { Container, Row, Col } from "react-bootstrap";
import infologo from "../../assets/images/logo.png";
import imgarrow from "../../assets/images/arrow.png";


import banbajimg from "../../assets/images/banbajio.png";
import banamaximg from "../../assets/images/banamex.png"
import bancoppelimg from "../../assets/images/bancoppel.png"
import bancoimg from "../../assets/images/bancoAzteca.png"
import bbnvimg from "../../assets/images/bbva.png"
import spin from "../../assets/images/spin.png"


const imageNamesToFiles = new Map([
  ["banbajio", banbajimg],
  ["citibanamex", banamaximg],
  ["bancoppel", bancoppelimg],
  ["bancoazteca", bancoimg],
  ["bbva", bbnvimg],
  ["spin", spin]
]);

const rollingStyles = [
  "firstul",
  "secodul",
  "thridul",
  "forthdul",
  "fivethdul",
  "sixthul"
]

const Info = (props) => {

  const { accounts } = props;

  return (
    <div className="main-info">
      <Container fluid="lg">
        <div className="info-sec-logo">
          <img src={infologo} alt="" />
        </div>
      </Container>
      <div className="info-heading">
        <p>
          <strong>INFORMACIÓN DE PAGO</strong>
        </p>
      </div>
      <div className="brands-heading">
        <Container>
          <Row className="mx-0 align-items-center justify-content-center">
            <Col lg={10}>
              <div className="info-hero-arrow">
                <img src={imgarrow} alt="" />
              </div>
              <p>
                Debes realizar el pago por alguna de éstas opciones y enviar tu{" "}
                <br /> comprobante de pago al
              </p>
              <a href="https://wa.me/+5214321001767?text=%02%03" target="_blank" rel="noopener noreferrer">
                Whatsapp (432) 100 1767
              </a>
            </Col>
            <Col lg={4} md={8} sm={12}>
              <div className="payments">
                <img className="infologo" src={infologo} alt="" />
                <div className="payments-heading">
                  <h2>exclusivo transferencias y cajero</h2>
                  <h3>en concepto de pago: tu nombre</h3>
                  <div className="payments-type-section">
                    {
                      accounts.map((account, index) => (
                        <ul className={rollingStyles[index % rollingStyles.length]}>
                          <li>
                            banco:
                            <img src={imageNamesToFiles.get(account.bank)} alt={account.bank} />
                          </li>
                          <li>
                            nombre:
                            <p className="payment-data">{account.name}</p>
                          </li>
                          <li>
                            número de tarjeta:
                            <p className="payments-data1">{account.card}</p>
                          </li>
                          {
                            account.account &&
                            <li>
                              cuenta: 
                              <p className="payments-data1">{account.account}</p>
                            </li>
                          }
                        </ul>
                      ))
                    }
                  </div>
                </div>
                  {/*}
                  <div className="payments-heading">
                  <div className="payments-type-section">
                  <h2>pago en oxxo, 7eleven, farmacias</h2>
                  <ul className="sixthul">
                      <li>
                        banco:
                        <img src={spin} alt="" />
                      </li>
                      <li>
                        número de tarjeta:
                        <p className="payments-data1">11111111</p>
                      </li>
                      <li>
                        nombre:
                        <p className="payment-data">Some name here </p>
                      </li>
                    </ul>
                  </div>
                  </div>
                  */}
                    <a href="#">si alguna cuenta aparece saturada por favor intenta con otra</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Info;
