import React from 'react'
import { Container } from "react-bootstrap";
import "./footer.css"
const Footer = () => {
  return (
    <div className='main-footer'>
        <div className="footer-contact">
          <Container fluid="lg">
                <a href="https://wa.me/+5214321001767?text=%02%03" target="_blank" rel="noopener noreferrer">
                PREGUNTAS AL WHATSAPP. <br className='br' />
                (432) 100 1767
                </a>
          </Container>
        </div>
    </div>
  )
}

export default Footer;
