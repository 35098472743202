import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AuthContext from '../../context/AuthContext';

export default function AdminBar() {

    const { logoutUser } = React.useContext(AuthContext)

    const navigate = useNavigate();

    return (
    <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
        <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TDSE
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Button
                    key="Sorteos"
                    onClick={() => {navigate('/admin')}}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                >
                    Sorteos    
                </Button>
                <Button
                    key="Cuentas"
                    onClick={() => {navigate('/accounts')}}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                >
                    Cuentas
                </Button>
            </Box>
            <Button onClick={() => logoutUser()} color="inherit">Logout</Button>
        </Toolbar>
        </AppBar>
    </Box>
    );
}