import * as React from 'react';

import "./apartadosdata.css"

import dayjs from 'dayjs';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';



const columns = [
    { field: 'name', headerName: 'Name', width: 110 },
    { field: 'lastname', headerName: 'Apellido', width: 110 },
    { field: 'phone', headerName: 'Telefono', width: 140 },
    { field: 'location', headerName: 'Ubicación', width: 140 },
    {
        field: 'unit_price',
        headerName: 'Precio Unitario',
        type: 'number',
        width: 120,
        valueGetter: (params) => {
            return params.row.purchase_price / params.row.packs;
        }
    },
    {
        field: 'packs',
        headerName: 'Paquetes',
        type: 'number',
        width: 100,
    },
    {
      field: 'purchase_price',
      headerName: 'Total de Compra',
      type: 'number',
      width: 140,
    },
    {
        field: 'created_at',
        headerName: 'Fecha de Apartado',
        width: 120,
    },
    {
        field: 'paid',
        headerName: 'Pagado',
        type: 'boolean',
        width: 100,
    },
    {
        field: 'paid_at',
        headerName: 'Fecha de Pago',
        width: 120,
    },
    {
        field: 'payment_method',
        headerName: 'Metodo de Pago',
        width: 120,
    },  
    {
      field: 'boletos',
      headerName: 'Boletos',
      width: 500,
      valueGetter: (params) => {
          return params.row.boletos.map((boleto) => boleto.no).join(', ');
      }
    },
  ];
  
  
  export default function ApartadosData({ apartados, pack, markApartadosPaid, markApartadosUnPaid, sendWhatsapp, deleteApartado }) {
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);

    const [paymentMethod, setPaymentMethod] = React.useState('efectivo');

    const [boleto, setBoleto] = React.useState('');

    const handleClose = () => {
      setOpen(false);
    };

    const handleCloseDelete = () => {
      setOpenDelete(false);
    };

    const [selected, setSelected] = React.useState([]);
    
    const rows = apartados.map((apartado, index) => {
        return {
            id: index,
            name: apartado.name,
            lastname: apartado.lastname,
            phone: apartado.phone,
            location: apartado.location,
            packs: apartado.boletos.length / pack,
            purchase_price: apartado.purchase_price,
            created_at: dayjs(apartado.created_at).format('DD/MM/YYYY'),
            paid: apartado.paid,
            paid_at: apartado.paid_at ? dayjs(apartado.paid_at).format('DD/MM/YYYY'): '',
            payment_method: apartado.payment_method,
            boletos: apartado.boletos,
        }
    }).filter((apartado) => {
      if (boleto === '') {
        return true;
      } else {
        return apartado.boletos.map((boleto) => boleto.no).includes(parseInt(boleto));
      }
    });

    const handleConfirmPayment = () => {
      markApartadosPaid(
        selected.map((index) => apartados[index]),
        paymentMethod
      );
      handleClose();
    }

    const handleDelete = () => {
      deleteApartado(
        selected.map((index) => apartados[index]),
      );
      handleCloseDelete();
    }

    return (
      <div style={{ height: 400, width: '100%', padding:'2%', marginTop:'2%' }}>
        <Stack spacing={2} direction='column' sx={{ justifyContent: 'center' }}>
        <div style={{display: 'flex', gap:'5%', justifyContent:'center'}}>
          <TextField
            id="outlined-basic"
            label="Buscar por boleto"
            variant="outlined"
            type='number'
            value={boleto}
            onChange={(event) => {
              setBoleto(event.target.value);
            }}
          />
          <Button
            variant='outlined'
            size='small'
            onClick={() => {
              sendWhatsapp(
                selected.map((index) => apartados[index]),
              );
            }}
            color='success'
          >
            <WhatsAppIcon/>   {'Whatsapp'}
          </Button>
        </div>
        <div className='container-data'>
          <Stack direction='row' spacing={2}>
            <Button
              variant='outlined'
              size='small'
              onClick={() => {
                setOpen(true);
              }}
            >
              Marcar pagado
            </Button>
            <Button
              variant='outlined'
              size='small'
              onClick={() => {
                markApartadosUnPaid(
                  selected.map((index) => apartados[index]),
                  false
                );
              }}
            >
              Deshacer Pago
            </Button>
            <Button
              variant='outlined'
              size='small'
              color="error"
              onClick={() => {
                setOpenDelete(true);
              }}
            >
              Eliminar
            </Button>
          </Stack>
        </div>
        </Stack>
        <br />
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            setSelected(newSelection)
          }
          }
          sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Método de pago</DialogTitle>
          <DialogContent>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Método de Pago</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentMethod}
                  label="Payment Method"
                  onChange={
                    (event) => {
                      setPaymentMethod(event.target.value);
                    }
                  }
                >
                  <MenuItem value={"efectivo"}>Efectivo</MenuItem>
                  <MenuItem value={"transferencia"}>Transferencia</MenuItem>
                </Select>
            </FormControl>
          </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirmPayment}>Confirmar</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>¿Seguro que desea eliminar?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Esta acción no se puede deshacer
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete}>Cancelar</Button>
            <Button onClick={handleDelete}>Confirmar</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
