import React from "react";
import axios from "axios";

import { useParams, useLocation } from "react-router-dom";

import Topbar from "../compountes/navbar/navbar";
import Footer from "../compountes/footer/footer";
import BoletoGenerator from "../compountes/boletogenerator/boletogenerator";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Boleto = () => {
    const search = useLocation().search;
    const query = new URLSearchParams(search).get("number");

    const { slug } = useParams();
    const [sorteoInfo, setSorteoInfo] = React.useState({title: null,
                                                        ticketPack: 6,
                                                        ticketDisplay: 10000,
                                                        image: null,
                                                        apartados: []});
    const [apartados, setApartados] = React.useState(null);
    const [isEmpty, setIsEmpty] = React.useState(false);

    React.useEffect(() => {
        const getSorteoData = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}sorteos-simple/${slug}`, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            const data = res.data;
            const sorteoInfo = {
                title: data.title,
                ticketPack: data.ticket_pack,
                ticketDisplay: data.ticket_display,
                image: data.image,
            }
            setSorteoInfo(sorteoInfo);
          } catch (err) {
            console.error(err);
          }
        };
        if (slug) {
          getSorteoData();
        }
    }, []);

    const getApartados = async (query) => {
        try {
          const res = await axios.get(`${baseUrl}apartados/${slug}`,
            {
              params: {
                boleto: query,
              }
            });
          if (res.data?.length === 0) {
            setApartados([]);
            setIsEmpty(true);
          } else {
            const apartado = res.data ? res.data[0] : []
            if (apartado.paid) {
              setApartados(res.data ? res.data[0] : []);
              setIsEmpty(false);
            } else {
              setApartados([]);
              setIsEmpty(true);
            }
          }
        } catch (error) {
          console.log(error);
        }
    }

    return (
        <div>
          <Topbar slug={slug} />
          <BoletoGenerator ticketNumber={query} sorteoInfo={sorteoInfo} apartados={apartados} getApartados={getApartados} isEmpty={isEmpty} setIsEmpty={setIsEmpty}/>
          <Footer />
        </div>
      );
}

export default Boleto;