import React, { useState } from "react";
import { Button, Col, Container, Modal, Row, Form } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import "./listamodel.css";
import jackpot from "../../assets/gifs/jackpot.gif";
import { completeNumberFormat, ticketNumberToParticipations } from "../../utils/tickets";
import getStateToCities from "../../utils/stateToCities";

const Listamodel = (props) => {
  const { slug, tickets, setTickets, sorteoInfo, ticketsToTake, setTicketsToTake, openModal, setOpenModal, postForm } = props;

  const title = sorteoInfo?.title;
  const ticketDisplay = sorteoInfo?.ticketDisplay;
  const ticketPack = sorteoInfo?.ticketPack;
  const price = sorteoInfo?.price;  

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [randomAmount, setRandomAmount] = useState(0);
  const [randomTickets, setRandomTickets] = useState(new Set());
  const [jackpotImage, setJackpotImage] = useState("");

  const [formPhone, setFormPhone] = useState("");
  const [formName, setFormName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [formState, setFormState] = useState("");
  const [formCity, setFormCity] = useState("");

  const handleClose = () => {
    setShow(false);
    setRandomAmount(0);
    setRandomTickets(new Set());
  };
  const handleShow = () => setShow(true);
  const handleCloseModalInner = () => setOpenModal(false);
  const handleShowModalInner = () => {
    setTicketsToTake(randomTickets);
    setOpenModal(true);
  };

  const stateToCities = getStateToCities();

  const getRandomTickets = (amount) => {
    const randomTickets = new Set();
    while (randomTickets.size < amount) {
      const randomTicket = tickets[Math.floor(Math.random() * ticketDisplay)];
      if (randomTicket.taken === false) {
        randomTickets.add(randomTicket.id);
      }
    }
    return randomTickets;
  };

  const handleMachine = () => {
    setJackpotImage(jackpot);
    const randomTickets = getRandomTickets(randomAmount);
    setRandomTickets(randomTickets);
    setTimeout(() => {
      setJackpotImage("");
    }
    , 5000);
  };

  const constructForm = (formName, formLastName, formPhone, formCity, formState, tickets, ticketDisplay, ticketPack, ticketsToTake) => {
    const ticketIds = [];
    ticketsToTake.forEach(ticketId => {
      const ticket = tickets.find(ticket => ticket.id === ticketId);
      const ticketParticipations = ticketNumberToParticipations(ticket.no, ticketDisplay, ticketPack);
      const ticketParticipationsIds = ticketParticipations.map(ticketParticipation => tickets.find(ticket => ticket.no === parseInt(ticketParticipation)).id);
      ticketIds.push(...[...ticketParticipationsIds, ticketId]);
    });

  const form = {
      "name": formName,
      "lastname": formLastName,
      "phone": formPhone,
      "location": formCity ? `${formCity}, ${formState}`:`${formState}`,
      "boletos": ticketIds
    };
    return form;
  };

  const handleApartar = () => {
    const res = postForm(constructForm(formName, formLastName, formPhone, formCity, formState, tickets, ticketDisplay, ticketPack, ticketsToTake));
    if (res) {
      setLoading(true);
      res.then((data) => {
        const newTickets = tickets.map(ticket => {
          if (ticketsToTake.has(ticket.id)) {
            return {...ticket, taken: true};
          }
          return ticket;
        });
        setTickets(newTickets);
        setTicketsToTake(new Set());
        const adminPhone = "5214321001767";
        const message = `Hola, Aparte boletos de la rifa!!\n${title}!!\n————————————\n🍀 *${ticketsToTake.size} BOLETOS:*\n${Array.from(ticketsToTake).map((ticketId) => (
          "*" + completeNumberFormat(tickets.find(ticket => ticket.id === ticketId)?.no, ticketDisplay) + "*" + " (" + ticketNumberToParticipations(tickets.find(ticket => ticket.id === ticketId)?.no, ticketDisplay, ticketPack).map(ticketParticipation => completeNumberFormat(parseInt(ticketParticipation), ticketDisplay)).join(", ") + ")"
        )).join("\n")}\n\n*Nombre:* ${formName} ${formLastName}\n\n1 BOLETO POR $${price}\n————————————\n*CUENTAS DE PAGO AQUÍ:*\nwww.${window.location.host}/${slug}/pagos\nEl siguiente paso es enviar foto del comprobante de pago por aquí y esperar tu confirmación . `
        const encodedMessage = encodeURI(message);
        const url = `https://wa.me/${adminPhone}?text=${encodedMessage}`;
        window.location.href = url;
        handleCloseModalInner();
        setLoading(false);
      });
    }
  }


  return (
    <>
      <div className="model">
        <Button className="list__search" onClick={handleShow}>
          MAQUINITA DE LA SUERTE
        </Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="modal-header"></Modal.Header>
          <Modal.Body>
            <div className="innerbody">
              <p>BOLETOS A GENERAR:</p>
              <select className="select" onChange={(e) => setRandomAmount(e.target.value)}>
                <option value="0" disabled="">
                  {""}
                  Selecciona
                </option>
                <option value="1">1 Boleto</option>
                <option value="2">2 Boletos</option>
                <option value="3">3 Boletos</option>
                <option value="4">4 Boletos</option>
                <option value="5">5 Boletos</option>
                <option value="6">6 Boletos</option>
                <option value="7">7 Boletos</option>
                <option value="8">8 Boletos</option>
                <option value="9">9 Boletos</option>
                <option value="10">10 Boletos</option>
                <option value="20">20 Boletos</option>
                <option value="30">30 Boletos</option>
                <option value="50">50 Boletos</option>
                <option value="100">100 Boletos</option>
              </select>
            </div>
            
            {randomAmount > 0 ? (
              <div className="modelresult" onClick={() => handleMachine()}>
                  {jackpotImage === "" ? ( 
                    <div className="modelresultinner">
                      <div className="participations">
                        {Array.from(randomTickets).map((ticketId, index) => (
                          index === randomTickets.size - 1 ? 
                            <h2 key={index}>{completeNumberFormat(tickets.find(ticket => ticket.id === ticketId)?.no, ticketDisplay)}</h2>
                              :
                            <h2 key={index}>{completeNumberFormat(tickets.find(ticket => ticket.id === ticketId)?.no, ticketDisplay)},</h2>
                        ))}
                      </div>
                      {randomAmount > 1 ? (
                        <h2>HAZ CLICK AQUÍ PARA GENERAR {randomAmount} BOLETOS AL AZAR!</h2>
                      ) : (
                        <h2>HAZ CLICK AQUÍ PARA GENERAR {randomAmount} BOLETO AL AZAR!</h2>
                      )}
                    </div>
                  ) : (
                    <img src={jackpotImage} alt="" loading="lazy" />
                  )}
              </div>
            ) : (
              <div className="modelresult"/>
            )}
            
            {randomTickets.size > 0 ? (
              <button
                className="button-lo-quiero"
                onClick={() => {
                  handleShowModalInner();
                  setShow(false);
                }}
              >
                LOS QUIERO!
              </button>
            ) : (
              null
            )}
          </Modal.Body>
        </Modal>
      </div>
      <Modal className="" show={openModal} onHide={handleCloseModalInner}>
        <Modal.Header closeButton className="modal-header"></Modal.Header>
        <Modal.Body>
          <div className="model-heading">
            <h3 className="text-center">
              LLENA TUS DATOS Y DA CLICK EN APARTAR
            </h3>
            {ticketsToTake.size > 1 ? (
              <p> {ticketsToTake.size} BOLETOS SELECCIONADOS</p>
            ) : (
              <p> {ticketsToTake.size} BOLETO SELECCIONADO</p>
            )}
          </div>
          <div className="form text-center">
            <input
              type="text"
              className=""
              inputMode="numeric"
              placeholder="NÚMERO WHATSAPP (10 dígitos)"
              pattern="\d{10}"
              onChange={(e) => setFormPhone(e.target.value)}
            />
            <input type="text" className="" placeholder="NOMBRE(S)" onChange={(e) => setFormName(e.target.value)}></input>
            <input type="text" className="" placeholder="APELLIDOS" onChange={(e) => setFormLastName(e.target.value)}></input>
            <select className="select2 select" onChange={(e) => setFormState(e.target.value)}>
              <option value="">SELECCIONA ESTADO</option>
              <option value="Estados Unidos">Estados Unidos</option>
              <option value="Otro Pais">Otro Pais</option>
              {Object.keys(stateToCities).map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {formState && formState !== "Estados Unidos" && formState !== "Otro Pais" ?
              <select className="select2 select" style={{marginTop: '1rem'}} onChange={(e) => setFormCity(e.target.value)}>
                <option value="">SELECCIONA MUNICIPIO</option>
                  {stateToCities[formState] ? stateToCities[formState].map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  )) : null}
              </select>
              :
              null
            }
            <small>
              ¡Al finalizar serás redirigido a whatsapp para enviar la
              información de tu boleto!
            </small>
            <p className="form-para text-center m-auto">Tu boleto sólo dura 12 horas apartado</p>
          </div>
          {loading ?
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem'}} >
              <CircularProgress />
            </div>
          :
          <button className="button-lo" onClick={() => handleApartar()}>
            APARTAR
          </button>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Listamodel;
