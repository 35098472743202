import "./boletotemplate.css"
import logo from "../../assets/images/logo.png"

const BoletoTemplate = (props) => {
    const {title, ticketNumber, ticketParticipations, image, name, lastName, state, city, paidDate} = props;
    return (
        <div className="ticket">
            <aside>{title}</aside>
            <section className="ticket__first-section">
                <section>
                    <img src={logo} alt="" />
                    <h4>SORTEOS CHOYS</h4>
                </section>
                <section>
                    <strong>Boleto:</strong>
                    <div>
                        <span>{ticketNumber}</span>
                        <ul>
                            {ticketParticipations?.map((participation, index) => (
                                index === ticketParticipations.length - 1 ?
                                    <li key={index}>{participation}</li>
                                    :
                                    <li key={index}>{participation},</li>
                            ))}
                        </ul>
                    </div>
                </section>
                <ul>
                    <li><p><strong>SORTEO:</strong></p><p>{title}</p></li>
                    <li><p><strong>NOMBRE:</strong></p><p>{name}</p></li>
                    <li><p><strong>APELLIDO:</strong></p><p>{lastName}</p></li>
                    <li><p><strong>ESTADO:</strong></p><p>{state}</p></li>
                    <li><p><strong>MUNICIPIO:</strong></p><p>{city}</p></li>
                    <li><p><strong>PAGADO:</strong></p><p>PAGADO</p></li>
                    <li><p><strong>COMPRA:</strong></p><p>{paidDate}</p></li>
                </ul>
            </section>
            <section className="ticket__second-section" style={{backgroundImage: `url(${image})`}}>
            </section>
            <section className="ticket__third-section">
                <h4>¡MUCHA SUERTE!</h4>
            </section>
            <aside>{title}</aside>
        </div>
    )
}

export default BoletoTemplate