import * as React from 'react'

import './sorteos.css'

import SorteoCard from '../sorteoCard/sorteoCard'



const Sorteos = (props) => {

    const { sorteos, goToSorteo } = props

    return (
        <div className='sorteo-container'>
            {
                sorteos.map((sorteo) => (
                    <div className='sorteo-card-container'>
                        <SorteoCard title={sorteo.title} image={sorteo.image} click={() => goToSorteo(sorteo.slug)}/>
                    </div>
                ))
            }
        </div>
    )
}

export default Sorteos