import * as React from "react";

import AuthContext from "../context/AuthContext";

const Login = () => {
    const { loginUser } = React.useContext(AuthContext);

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser(username, password);
    }

    return (
        <div className="login">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username</label>
                <input type="text" id="username" value={username} onChange={(event) => setUsername(event.target.value)} />
                <label htmlFor="password">Password</label>
                <input type="password" id="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                <button type="submit">Login</button>
            </form>
        </div>
    );

}


export default Login;