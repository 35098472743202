import "./App.css";

import { AuthProvider } from "./context/AuthContext";

import Lista from "./pages/lista";

import Mainhero from "./compountes/mainhero/mainhero";
import  Navigation  from "./compountes/navigation/index";

function App() {
  return (
    <>
      <AuthProvider>
        <Navigation/>
      </AuthProvider>
    </>
  );
}

export default App;
