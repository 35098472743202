import React from "react";
import axios from "axios";

import Hero from "../compountes/hero/hero";
import Lucky from "../compountes/lucky/lucky";

import Topbar from "../compountes/navbar/navbar";
import Footer from "../compountes/footer/footer";

import CircularProgress from '@mui/material/CircularProgress';

import { useParams } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Lista = () => {
  const { slug } = useParams();
  const [loading, setLoading] = React.useState(true);

  const [sorteoInfo, setSorteoInfo] = React.useState({title: null,
                                                      slug: null,
                                                      price: 199,
                                                      ticketPack: 6,
                                                      ticketDisplay: 10000,
                                                      active: true,
                                                      dateEnd: null,
                                                      image: null,
                                                      bonos: null,
                                                      presorteos: null});

  const [tickets, setTickets] = React.useState([]);

  React.useEffect(() => {
    const getSorteoData = async () => {
      try {
        const res = await axios.get(`${baseUrl}sorteos/${slug}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = res.data;
        const sorteoInfo = {
          title: data.title,
          slug: data.slug,
          price: data.price,
          ticketPack: data.ticket_pack,
          ticketDisplay: data.ticket_display,
          active: data.active,
          dateEnd: data.end,
          image: data.image,
          bonos: data.bonos,
          presorteos: data.presorteos
        };
        const tickets = data.boletos.sort((a, b) => a.no - b.no);
        setSorteoInfo(sorteoInfo);
        setTickets(tickets);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (slug) {
      getSorteoData();
    }
  }, [slug]);

  const postForm = async (form) => {
    try {
      const res = await axios.post(`${baseUrl}apartados/${slug}/`, form, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = res.data;
      return data;
    } catch (err) {
      console.error(err);
    }
  }


  return (
    <>
      <Topbar slug={slug}/>
      {loading ?
        <div style={{display: 'flex', height:'50vh', justifyContent: 'center', alignItems: 'center', padding: '1rem'}} >
          <CircularProgress />
        </div>
        :
        <div>
          <Hero title={sorteoInfo.title} date={sorteoInfo.dateEnd} image={sorteoInfo.image} price={sorteoInfo.price} ticketPack={sorteoInfo.ticketPack} bonos={sorteoInfo.bonos} presorteos={sorteoInfo.presorteos} />
          <Lucky slug={slug} tickets={tickets} setTickets={setTickets} sorteoInfo={sorteoInfo} postForm={postForm} />
          <Footer />
        </div>
      }
    </>
  );
};

export default Lista;
