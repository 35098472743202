import React from "react";
import "./navbar.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import leftimg from "../../assets/images/left.png";
import rightimg from "../../assets/images/right.webp";
import { Link as RouteLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
function Topbar(props) {
  const { slug } = props;
  
  return (
    <>
      <div className="topnav">
        <Navbar expand="lg" className="navbar">
          <Container fluid="lg">
            <RouteLink className="right-smallicon" to={slug ? `/${slug}/pagos` : "/pagos"}>
              <img src={rightimg} alt="" />
            </RouteLink>
            <Navbar.Brand href={slug ? `/${slug}#` : ""}>
              <img src={logo} className="nav-logo" alt="" />
            </Navbar.Brand>
            <RouteLink className="small-logo" to={slug ? `/${slug}`: ""}>
              <img src={logo} className="nav-logo" alt="" />
            </RouteLink>
            <Navbar.Collapse id="navbarScroll">
              <Nav className="my-2 my-lg-0 ms-auto" navbarScroll>
                <HashLink
                  className="nav-link"
                  to={slug ? `/${slug}#inicio` : "#inicio"}
                >
                  Inicio
                </HashLink>
                <HashLink
                  className="nav-link"
                  to={slug ? `/${slug}#preguntas` : "#preguntas"}
                >
                  Preguntas Frecuentes
                </HashLink>
                <HashLink
                  className="nav-link"
                  to={slug ? `/${slug}#nosotros` : "#nosotros"}
                >
                  Nosotros
                </HashLink>
                <HashLink
                  className="nav-link"
                  to={slug ? `/${slug}#contacto` : "#contacto"}
                >
                  Contacto
                </HashLink>
              </Nav>
              {slug ? (
                <div className="navbtn">
                  <RouteLink className="nav-link" to={slug ? `/${slug}/lista` : ""}>
                    Comprar Boletos
                  </RouteLink>
                </div>
              ) : (
                null
              )}
            </Navbar.Collapse>
            {slug ? (
              <RouteLink className="left-smallicon" to={slug ? `/${slug}/lista` : ""}>
                <img src={leftimg} alt="" />
              </RouteLink>
            ) : (
              null
            )}
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Topbar;
