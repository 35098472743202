import * as React from 'react';

import dayjs from 'dayjs';

import './sorteodata.css';

import { TextField, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const SorteoData = ({ sorteo, setSorteo, edit, setEdit, setUploadImage, previewURL, setPreviewURL }) => {

    return (
        <div>
            <div className='sorteo-data-container'>
                <div className='input-container'> 
                    <TextField
                        label="Título"
                        value={sorteo.title || ""}
                        onChange={(e) => setSorteo({ ...sorteo, title: e.target.value })}
                        disabled={!edit}
                    />
                    <div style={{display:'flex', justifyContent: 'space-between'}}>
                        <div className='input-container-inner-left'> 
                            <img src={previewURL} loading='lazy' alt="Sube una imágen"/>
                            <input
                                type="file" 
                                accept="image/*"
                                disabled={!edit}
                                onChange={(e) => {
                                    setSorteo({ ...sorteo, image: e.target.files[0] });
                                    setUploadImage(true);
                                    setPreviewURL(URL.createObjectURL(e.target.files[0]))
                                }}
                            />
                        </div>
                        <div className='input-container-inner-right'> 
                            <TextField
                                label="Precio del boleto"
                                type='number'
                                value={sorteo.price || ""}
                                onChange={(e) => setSorteo({ ...sorteo, price: e.target.value })}
                                disabled={!edit}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Fecha de cierre"
                                    value={dayjs(sorteo.end) || dayjs()}
                                    onChange={(date) => setSorteo({ ...sorteo, end: dayjs(date) })}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={!edit}
                                />
                            </LocalizationProvider>
                            <TextField
                                label="URL"
                                value={sorteo.slug || ""}
                                onChange={(e) => setSorteo({ ...sorteo, slug: e.target.value })}
                                disabled={!edit}
                            />
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox 
                                        checked={sorteo.active || false}
                                        onChange={(e) => setSorteo({ ...sorteo, active: e.target.checked })} 
                                    />}
                                    label="Activo"
                                    disabled={!edit}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <Button variant="contained" onClick={() => setEdit(!edit)}>{edit ? 'Guardar' : 'Editar'}</Button>
                </div>
            </div>
        </div>
    )

}

export default SorteoData;