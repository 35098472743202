import React from "react";
import axios from "axios";

import { useParams } from "react-router-dom";

import Mainhero from "../compountes/mainhero/mainhero";
import Topbar from "../compountes/navbar/navbar";
import Footer from "../compountes/footer/footer";

const Home = () => {
  const { slug } = useParams();
  const [sorteoInfo, setSorteoInfo] = React.useState({title: null,
                                                      slug: null,
                                                      price: 199,
                                                      ticketPack: 6,
                                                      ticketDisplay: 10000,
                                                      active: true,
                                                      dateEnd: null,
                                                      image: null});

                                                      
  React.useEffect(() => {
    const getSorteoInfo = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}sorteos-simple/${slug}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = res.data;
        setSorteoInfo(data);
      } catch (err) {
        console.error(err);
      }
    };
    getSorteoInfo();
  }, []);
    
  return (
    <div>
      <Topbar slug={slug} />
      <Mainhero slug={slug} image={sorteoInfo.image} />
      <Footer />
    </div>
  );
};

export default Home;
