import * as React from "react";

import { useNavigate } from "react-router-dom";

import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";

import AdminBar from "../compountes/adminbar/adminbar";
import Sorteos from "../compountes/admin/sorteos/sorteos";
import CreateSorteo from "../compountes/createsorteo/createsorteo";

const Admin = () => {

    const navigate = useNavigate();
    const { axios, initialized } = useAxios();

    const [sorteos, setSorteos] = React.useState([]);

    const [creationLoading, setCreationLoading] = React.useState(false);

    React.useEffect(() => {
        const getSorteos = async () => {
            try {
                const response = await axios.get("/sorteos");
                setSorteos(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        if (initialized) getSorteos();
    }, [initialized]);

    const goToSorteo = (slug) => {
        navigate(`/admin/${slug}`);
    }

    const createSorteo = async (sorteo) => {
        try {
            setCreationLoading(true);
            const response = await axios.post("/sorteos/", sorteo);
            setSorteos([...sorteos, response.data]);
            setCreationLoading(false);
        } catch (error) {
            alert("Error al crear el sorteo");
            console.log(error);
        }
    }

    return (
        <div style={{ height: '100vh'}}>
            <AdminBar />
            <Sorteos sorteos={sorteos} goToSorteo={goToSorteo} />
            <CreateSorteo
                createSorteo={createSorteo}
                loading={creationLoading}
            />
        </div>
    )
}

export default Admin;