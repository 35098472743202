import React from "react";
import { Container , Row , Col } from "react-bootstrap";
import "./mainherolanding.css";
import whatsimg from "../../assets/images/whatsapp.webp"
import faceimg from "../../assets/images/facebook.webp"
import profileimg from "../../assets/images/profile.jpg"
import CircularProgress from '@mui/material/CircularProgress';
const MainheroLanding = (props) => {
  const { activeSorteos } = props;
    
  return (
    <div className="mainherolanding-section" id="inicio">
      <div className="details-sec bg-white">
        <div className="heading-details-sec">
          <p>
            <strong>NUESTROS SORTEOS ACTIVOS</strong>
          </p>
        </div>
      </div>
      <div className="active-sorteo-card-container">
        {activeSorteos.length !== 0 ?
          <>
            {activeSorteos?.map((sorteo) => (
              <a href={`/${sorteo.slug}`}>
                <div class = "active-sorteo-card">
                  <img src={sorteo.image} alt="car" style={{ width: "12rem", height: "10rem" }} loading="lazy" />
                  <h5>{sorteo.title}</h5>
                </div>
              </a>
            ))}
          </>
          :
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%"}}>
            <CircularProgress />
          </div>
        }
      </div>
      <div className="details-sec bg-white">
        <div className="heading-details-sec" id="preguntas">
          <p>
            <strong>PREGUNTAS FRECUENTES</strong>
          </p>
        </div>
        <Container>
          <Row className="mx-0 justify-content-center">
            <Col lg={8} md={10} sm={12}>
              <div className="details-para">
                <h4 className="text-center">¿CÓMO SE ELIGE A LOS GANADORES?</h4>
                <p>
                  <strong>
                    Todos nuestros sorteos se realizan en base a la
                  </strong>&nbsp;
                   <a
                    href="https://www.lotenal.gob.mx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <strong>
                      <u>Lotería Nacional para la Asistencia Pública</u>
                    </strong>
                  </a>
                      <strong>&nbsp;mexicana.​</strong>
                </p>
                <p className="py-3">
                  <strong>
                  El ganador de SORTEOS CHOYS será el participante cuyo número de boleto coincida con las últimas cifras del primer premio ganador de la Lotería Nacional (las fechas serán publicadas en nuestra página oficial).
                  </strong>
                </p>
                <h4>
                ¿QUÉ SUCEDE CUANDO EL NÚMERO GANADOR ES UN BOLETO NO VENDIDO?
                </h4>
                <p>
                  <strong>Se elige un nuevo ganador realizando la misma dinámica en otra fecha cercana (se anunciará la nueva fecha).</strong>
                </p>
                <p className="py-3">
                  <strong>Esto significa que, ¡Tendrías el doble de oportunidades de ganar con tu mismo boleto!</strong>
                </p>
                <h4>
                ¿DÓNDE SE PUBLICA A LOS GANADORES?
                </h4>
                <p>
                <strong>En nuestra página oficial de Facebook&nbsp;</strong>
                <a href="https://www.facebook.com/pg/100087618053359" rel="noopener noreferrer" target="_blank"><strong><u>SORTEOS CHOYS</u></strong></a><strong>&nbsp;puedes encontrar todos y cada uno de nuestros sorteos anteriores, así como las transmisiones en vivo con Lotería Nacional y las entregas de premios a los ganadores!</strong>
                </p>
                <hr className="hr my-5" />
                <p className="text-center"><strong>Encuentra transmisión en vivo de los sorteos en nuestra página de Facebook en las fechas indicadas a las 20:00 hrs CDMX. ¡No te lo pierdas!</strong></p>
                <p className="text-center soical"><strong>Envíanos tus preguntas a</strong></p>
                <div className="icons mb-5">
                 <img src={whatsimg} alt="" />
                 <img src={faceimg} alt="https://www.facebook.com/pg/100087618053359" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="heading-details-sec" id="nosotros">
          <p>
            <strong>ACERCA DE NOSOTROS</strong>
          </p>
        </div>
        <div className="lotter-car">
          <div className="para-lotter">
        <p>Sorteos ENTRE AMIGOS en base a LOTERIA NACIONAL</p>
        <p>ARRIESGA POCO Y GANA MUCHO!</p>
          </div>
        </div>
        <div className="heading-details-sec" id="contacto">
          <p>
            <strong>CONTÁCTANOS</strong>
          </p> 
        </div>
        <div className="contact text-center">
          <a href="https://wa.me/+5214321001767?text=%02%03" target="_blank" rel="noopener noreferrer">
          WHATSAPP: (432) 100 1767
          </a>
          <div className="contact-icons py-5">
            <a href="https://wa.me/+5214321001767?text=%02%03" target="_blank" rel="noopener noreferrer">
              <img src={whatsimg} alt="" />
            </a>
            <a href="https://www.facebook.com/pg/100087618053359" rel="noopener noreferrer" target="_blank">
              <img src={faceimg} alt="" />
            </a>
          </div>
        </div>
        <div className="profile">
        <iframe className="inicio_fbembedded" src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/100087618053359%2F&amp;tabs&amp;width=340&amp;height=130&amp;small_header=false&amp;adapt_container_width=false&amp;hide_cover=false&amp;show_facepile=false&amp;appId=357721409026288" width="340" height="130" scrolling="no" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MainheroLanding;